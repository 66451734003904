import { Descriptions } from 'antd';
import './../styles/Scoreboard.css';

function ScoreboardPlayers(values) {
    return values.values.map((value) =>
        <>
            <Descriptions bordered column={{ xs: 2 }}>
                <Descriptions.Item label={value.name} labelStyle={{ fontSize: '130%' }} contentStyle={{ fontSize: '130%', minWidth: 'fitContent' }}>{value.score}</Descriptions.Item>
            </Descriptions>
        </>
    )
}
export default ScoreboardPlayers;