import Scoreboard from './components/Scoreboard';
import title from './images/title.png';
import data from './data/data.json';
import './styles/App.css'

import Downloads from './components/Downloads';

function App() {

  const getData = () =>{
    fetch('https://bowling2gether.onrender.com')
    .then(response => response.json())
    .then((response) =>{
      return response;
    })
  }


  return (
    <div>
      <Downloads></Downloads>
      <img src={title} alt="bowling2gether" className='imgTitle'></img>
      <Scoreboard values={data}></Scoreboard>
    </div>
  );
}

export default App;