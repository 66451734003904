import { Button, Descriptions, Divider } from 'antd';

function Downloads() {
    return (
        <>
            <Descriptions title={"Downloads"} bordered={true} contentStyle={{ border: "0px" }}>
                <Descriptions.Item><Button type='primary'>Download Windows</Button></Descriptions.Item>
                <Descriptions.Item><Button type='primary'>Download Android</Button> </Descriptions.Item>
            </Descriptions>
            <Divider orientation='left'></Divider>
        </>
    );
}

export default Downloads;