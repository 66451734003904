import { Collapse, Divider} from 'antd';
import ScoreboardPlayers from './ScoreboardPlayers';

const {Panel} = Collapse;

function Scoreboard(values){
    return values.values.map((value) =>
        <>
            <Collapse size="large">
                <Panel header={'Room Code: ' + value.code} extra={'Winner: ' + value.winner}>
                    <ScoreboardPlayers values={value.players}></ScoreboardPlayers>    
                </Panel> 
            </Collapse>
            <Divider orientation='left'></Divider>
        </>
    )
}
export default Scoreboard;